<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
    </v-breadcrumbs>
		<h1 class="nutriyou-h1">Dettagli</h1>
		<BookingCard v-if="bookingDetails" :booking="bookingDetails" @bookingModified="LoadBookingDetails">
		</BookingCard>
	</div>
</template>

<script>
import BookingCard from "./components/BookingCard.vue";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	components: {
		BookingCard,
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			LoadingData: false,
			bookingDetails: null,
		};
	},

	computed: {
		BookingId() {
			return this.$route.params.BookingId;
		},
	},
	created() {
		this.BookingsService = new CrudClient("CurrentNutritionist/Bookings");
		this.PatientsService = new CrudClient("CurrentNutritionist/Patients");
	},
	async mounted() {
		await this.LoadBookingDetails();

		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.areaNutritionists"),
			disabled: false,
			exact: true,
			to: "/AreaNutritionists",
		});

		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: false,
			exact: true,
			to: "/AreaNutritionists/Bookings",
		});

		this.breadcrumbsItems.push({
			text: this.BookingId,
			disabled: true,
			exact: true,
			to: "/AreaNutritionists/Bookings/" + this.BookingId,
		});
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadBookingDetails() {
			try {
				this.LoadingData = true;
				this.bookingDetails = await this.BookingsService.Get(this.BookingId);
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>
<style>
</style>
<i18n>
{
	"it": {
		"pageTitle": "Appuntamenti",
		"pageMetaDescription": "-",
		"NoBookings": "Nessun appuntamento trovato"
	}
}
</i18n>
