<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
    </v-breadcrumbs>

		<v-container>
			<v-row dense>
				<v-col>
					<h1 class="nutriyou-h2 text-left">{{ $t("pageTitle") }}</h1>
				</v-col>
				<v-col class="text-right" align-self="center">
					<v-btn icon to="./Bookings">
						<v-icon size="50" color="primary">fas fa-list</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		<!-- <v-btn v-if="DefaultMeetingRoomUrl" large block color="primary" class="text-capitalize"
			:href="DefaultMeetingRoomUrl" target="_blank"><v-icon left>fas fa-video</v-icon>
			Vai alla tua Stanza Virtuale</v-btn> -->
		<div class="d-flex mb-3">
			<v-btn color="grey lighten-1" class="mt-4" @click="setToday">
				Oggi
			</v-btn>
			<v-btn icon class="mt-4 mx-2" @click="$refs.calendar.prev()">
				<v-icon>fas fa-chevron-left</v-icon>
			</v-btn>
			<v-btn icon class="mt-4 mx-2" @click="$refs.calendar.next()">
				<v-icon>fas fa-chevron-right</v-icon>
			</v-btn>
			<v-select v-model="type" style="max-width: 200px;" :items="types" item-text="Text" item-value="Value"
				class="mr-3" hide-details label=""></v-select>

			<v-spacer></v-spacer>

			<v-select v-model="filterByBookingType" multiple :items="filterByBookingTypeItems" class="mr-3"
				item-text="Name" item-value="Value" @change="LoadBookings()" label="Servizio" clearable>
			</v-select>

			<v-select v-model="filterByStatus" multiple :items="filterByStatusItems" item-text="Name" item-value="Value"
				@change="LoadBookings()" label="Stato appuntamento" clearable>
			</v-select>
		</div>
		<v-sheet>
			<v-alert v-if="!LoadingData && events.length === 0" color="info" dark>
				{{ $t("NoBookings") }}
			</v-alert>

			<v-calendar ref="calendar" v-model="calendarValue" :weekdays="[1, 2, 3, 4, 5, 6, 0]" :type="type"
				:events="events" :event-color="getEventColor" @change="LoadBookings" @click:more="viewDay"
				@click:event="showEvent" @click:date="viewDay"></v-calendar>
		</v-sheet>
		<div class="mt-5">
			<v-switch label="Abilita Esportazione del Calendario" v-model="ExportCalendar"
				@change="ExportCalendarChanged"></v-switch>
			<v-text-field v-if="ExportCalendar" style="width:300px" ref="calendarUrl"
				label="Url per importare il calendario" readonly :value="CalendarUrl" append-icon="far fa-copy"
				@click:append="copyCalendarUrl"></v-text-field>

		</div>
	</div>
</template>
<script>
import api from "@/services/api"
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

export default {
	created() {
		this.BookingsService = new CrudClient("CurrentNutritionist/Bookings");
		this.NutritionistsService = new CrudClient("CurrentNutritionist/Nutritionist");
		this.UnavailableDatesService = new CrudClient("CurrentNutritionist/UnavailableDates");
	},

	data: () => ({
		breadcrumbsItems: [
			{
				text: "Home",
				disabled: false,
				exact: true,
				to: "/",
			},
		],
		filterByStatus: [1, 2, 4],
		filterByStatusItems: [
			{ Name: "Prenotato", Value: 1 },
			{ Name: "Completato", Value: 2 },
			{ Name: "Annullato", Value: 3 },
			{ Name: "In attesa di pagamento", Value: 4 }
		],
		filterByBookingType: [],
		filterByBookingTypeItems: [
			{ Name: "Incontro Conoscitivo", Value: 1 },
			{ Name: "Visita Nutrizionale", Value: 2 },
			{ Name: "Consegna e spiegazione del piano", Value: 3 },
		],
		LoadingData: false,

		type: "month",
		types: [{
			Text: "Mese",
			Value: "month"
		},
		{
			Text: "Settimana",
			Value: "week"
		},

		{
			Text: "Giorno",
			Value: "day"
		}],
		mode: "stack",

		weekday: [0, 1, 2, 3, 4, 5, 6],
		calendarValue: "",
		events: [],
		lastFilterInterval: null,
		DefaultMeetingRoomUrl: null,
		ExportCalendar: false,
		CalendarKey: null,
		CalendarUrl: null,

		UnavailableDates: null
	}),
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.areaNutritionists"),
			disabled: false,
			exact: true,
			to: "/AreaNutritionists",
		});

		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.calendar"),
			disabled: true,
			exact: true,
			to: "/AreaNutritionists/Calendar",
		});

		await this.LoadNutritionstData();
		await this.LoadUnavailableDate();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async copyCalendarUrl() {
			await navigator.clipboard.writeText(this.CalendarUrl)
		},

		async LoadUnavailableDate() {
			const res = await this.UnavailableDatesService.GetPaged({
				skip: 0,
				fields: "*"
			});

			this.UnavailableDates = res.Data;
		},

		async ExportCalendarChanged() {
			try {
				const dto = {
					ExportCalendar: this.ExportCalendar
				}
				await this.NutritionistsService.Patch(null, dto, false);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			}
		},
		async LoadNutritionstData() {
			const res = await this.NutritionistsService.GetSelectedFields(null, "DefaultMeetingRoomUrl, ExportCalendar, CalendarKey");

			this.DefaultMeetingRoomUrl = res.DefaultMeetingRoomUrl;
			this.ExportCalendar = res.ExportCalendar;
			this.CalendarKey = res.CalendarKey;
			this.CalendarUrl = `${api.url}CalendarExport/${this.CalendarKey}`;
		},

		setToday() {
			this.calendarValue = "";
		},
		viewDay({ date }) {
			this.calendarValue = date
			this.type = "day"
		},

		VueCalendarToJsDate(calendarDate) {
			return new Date(calendarDate.year, calendarDate.month - 1, calendarDate.day);
		},

		AddDays(jsDate, days) {
			const retJsDate = new Date(jsDate);
			retJsDate.setUTCDate(jsDate.getUTCDate() + days);
			return retJsDate;
		},

		FormatDate(jsDate) {
			return `${jsDate.getFullYear()}/${jsDate.getMonth() + 1}/${jsDate.getDate()}`;
		},

		showEvent({ event }) {
			if (event.booking) {
				this.$router.push("/AreaNutritionists/Bookings/" + event.booking.BookingId);
			}
		},
		getDatesBetween(startDate, endDate) {
			const datesArray = [];
			const currentDate = new Date(startDate);
			// eslint-disable-next-line no-unmodified-loop-condition
			while (currentDate <= endDate) {
				datesArray.push(new Date(currentDate));
				currentDate.setDate(currentDate.getDate() + 1);
			}
			return datesArray;
		},

		async LoadBookings(event) {
			let start, end;
			if (event) {
				this.lastFilterInterval = { start: event.start, end: event.end }
				start = event.start;
				end = event.end;
			} else {
				start = this.lastFilterInterval.start;
				end = this.lastFilterInterval.end;
			}

			const formattedEndDate = (this.FormatDate(this.AddDays(this.VueCalendarToJsDate(end), 1)));

			try {
				let filter = null;

				filter = `BookingTime:between:${start.date},${formattedEndDate}`;

				if (this.filterByStatus.length) {
					if (filter) filter += ";";
					else filter = "";
					filter += "BookingStatusId:in:" + this.filterByStatus.join(",");
				}

				if (this.filterByBookingType.length) {
					if (filter) filter += ";";
					else filter = "";

					filter += "BookingTypeId:in:" + this.filterByBookingType.join(",");
				}

				this.LoadingData = true;
				const res = await this.BookingsService.GetPaged({
					skip: 0,
					fields: "*",
					filter: filter,
					orderBy: "BookingId",
				});
				const items = res.Data;
				const events = [];

				for (let i = 0; i < items.length; i++) {
					const allDay = false;
					const eventStart = new Date(items[i].BookingTime);

					let color;
					switch (items[i].BookingStatusId) {
						case 1:
							color = "blue";
							break;
						case 2:
							color = "success";
							break;
						case 3:
						case 5:
							color = "red";
							break;
						case 4:
							color = "warning";
							break;
					};

					events.push({
						booking: items[i],
						name: items[i].Patient.DisplayName,
						start: eventStart,
						end: new Date(eventStart.getTime() + items[i].Duration * 60000),
						color: color,
						timed: !allDay,
					})
				}

				await this.LoadUnavailableDate();
				this.$log.debug(this.UnavailableDates);

				for (let i = 0; i < this.UnavailableDates.length; i++) {
					const allDay = true;
					const unavailablePeriodStart = new Date(this.UnavailableDates[i].StartDate);
					const unavailablePeriodEnd = new Date(this.UnavailableDates[i].EndDate);

					const dates = this.getDatesBetween(unavailablePeriodStart, unavailablePeriodEnd);

					dates.forEach(element => {
						events.push({
							name: "Non Disponibile",
							start: element,
							color: "grey",
							timed: !allDay,
						});
					});
				}

				this.events = events
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		getEventColor(event) {
			return event.color
		},
	},
}

</script>
<i18n>
{
	"it": {
		"pageTitle": "Calendario",
		"pageMetaDescription": "-",
		"NoBookings": "Nessun appuntamento trovato"
	}
}
</i18n>
